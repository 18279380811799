import React from 'react';
import { GetAfreeQuoteButton } from 'components/Shared/Button/Button';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { MetricsElement } from 'react-metrics';
import './AfterHeroSection.scss';

const CallToActionLink = ({ data }) => {
  if (data) {
    return (
      <MetricsElement>
        <GetAfreeQuoteButton
          data-metrics-event-name="afterhero_cta_click"
          data-metrics-label={data.text}
          to={data.url}
        >
          {data.text}
        </GetAfreeQuoteButton>
      </MetricsElement>
    );
  }
  return null;
};

const AfterHeroSection = ({ data }) => (
  <div className="after-hero">
    <ContentBox>
      <h1 className="after-hero__title">{data.title}</h1>
      {data.subtitle ? (
        <h2 className="after-hero__subtitle">{data.subtitle}</h2>
      ) : (
        ''
      )}
      <hr />
      <div
        className="after-hero__text"
        dangerouslySetInnerHTML={{
          __html: data.description,
        }}
      />
      <CallToActionLink data={data.callToActionButton} />
    </ContentBox>
  </div>
);

export default AfterHeroSection;
