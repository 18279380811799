import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import AfterHeroSection from 'components/AfterHeroSection/AfterHeroSection';
import PreFooter from 'components/PreFooter/PreFooter';

const SmartpayPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.smartpayYaml.title} />
      <AfterHeroSection data={data.smartpayYaml.afterHeroSection} />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default SmartpayPage;

export const pageQuery = graphql`
  query SmartpayPageQuery {
    smartpayYaml {
      id
      title

      afterHeroSection {
        title
        description
        callToActionButton {
          text
          url
        }
      }
    }
  }
`;
